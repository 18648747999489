import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reviewscriptform from "../components/activecampaign/reviewscriptform/reviewscriptform"
// import Reviewscriptform from "../components/activecampaign/reviewscriptform/reviewscriptform"


let colorYellow = {
  color: 'yellow'
}

let colorWhite = {
  color: 'white'
}


const IndexPage = () => (
  <Layout>

    <SEO title="The Ultimate Guide To Getting Google Reviews - Five Star Review System" />

    <div className="form-message px-5 py-3 mx-auto text-center" style={{backgroundColor: "#00163f"}}>
      <span className="h4 " style={colorYellow}>Fill in the form below to receive your free <span style={colorWhite}>"How To Ask For Google Reviews"</span> script.</span>
    </div>
    <Reviewscriptform
      lb='general-info'
    />

  </Layout>
)

export default IndexPage
